<h1 *ngIf="showTitle" mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>

    <p *ngIf="!isPrompt">{{message}}</p>

    <mat-form-field *ngIf="isPrompt" style="width: 100%;">
        <mat-label>{{message}}</mat-label>
        <input matInput [(ngModel)]="result" />
    </mat-form-field>

</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="ok()" color="primary">{{okLabel}}</button>
    <button mat-raised-button (click)="cancel()" color="warn" *ngIf="showCancel">{{cancelLabel}}</button>
</div>
